//=require ../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../node_modules/swiper/dist/js/swiper.min.js
//=require ../../node_modules/lightbox2/dist/js/lightbox.min.js
//=require ../../node_modules/waypoints/lib/jquery.waypoints.min.js
//=require ../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js

/* jshint -W030 */
!function($, window) {
  "use strict";

    // Vars
    var $doc = $(document), $win = $(window), SITE = SITE || {};

    SITE = {
        // Init
        init: function() {
          var obj, self = this;
          for (obj in self) if (self.hasOwnProperty(obj)) {
            var _method = self[obj];
            void 0 !== _method.selector && void 0 !== _method.init && $(_method.selector).length > 0 && _method.init();
          }
        },

        //Multi Level Menu
        MultiLevelMenu: {
          selector: "#nav",
          init: function() {
            var base = this, container = $(base.selector);

            var el = $("#nav ul li a");
            var elActive = $("#nav > ul").find("a.active");

            elActive.parent("li").addClass("active");
            elActive.parent("li").parents("li").addClass("active-trail");

            if($("#nav ul ul").length) {
              $("#nav > ul").addClass("sublvl");
            }

            el.each(function() {
              if($(this).next("ul").length) {
                $(this).after('<span class="expand"></span>');
              }
            });

            $("#nav ul li .expand").on("click", function() {
              $(this).parent("li").toggleClass('open');
            });
          }
        },

        //Data to BG
        DataToBg: {
          selector: "*[data-bg]",
          init: function() {
            var base = this, container = $(base.selector);

            container.each(function() {
              var bg = $(this).data('bg');
              if (bg.substr(0, 1) === '#') {
                $(this).css({
                  'background-color': bg
                });
              }else{
                $(this).css({
                  'background-image': 'url('+bg+')'
                });
              }
            });
          }
        },

        // Fixed navigation
        NavScrolled: {
          selector: ".main-header",
          init: function () {
            var base = this, container = $(base.selector);
            if ($(document).scrollTop() > 60) {
              container.addClass("scrolled");
            } else {
              container.removeClass("scrolled");
            }
          }
        },

        // Burger menu
        BurgerMenu: {
          selector: ".trigger-menu",
          init: function () {
            var base = this, container = $(base.selector);
            $(document).on('click', base.selector, function(e) {
              e.preventDefault();
              $(this).toggleClass('open');
              $('body').toggleClass('open-menu');
            });
          }
        },

        // on scroll animation
        OsAnimation: {
          init: function () {
            function onScrollInit( items, trigger ) {
              items.each( function() {
                var osElement = $(this),
                osAnimationClass = osElement.attr('data-os-animation'),
                osAnimationDelay = osElement.attr('data-os-animation-delay');

                osElement.css({
                  '-webkit-animation-delay':  osAnimationDelay,
                  '-moz-animation-delay':     osAnimationDelay,
                  'animation-delay':          osAnimationDelay
                });

                var osTrigger = ( trigger ) ? trigger : osElement;

                osTrigger.waypoint(function() {
                  osElement.addClass('animated').addClass(osAnimationClass);
                },{
                  triggerOnce: true,
                  offset: '90%'
                });
              });
            }

            onScrollInit( $('.os-animation') );
            $('.staggered-animation-container').each(function() {
              onScrollInit( $(this).find('.staggered-animation'), $(this) );
            });
          }
        },


        // Swiper gallery
        ContentGallery: {
          selector: ".swiper-galerie",
          init: function () {
            var base = this, container = $(base.selector);
            var mySwiper = new Swiper(base.selector, {
              slidesPerView: 1,
              spaceBetween: 10,
              speed: 1200,
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              effect: 'fade',
              loop: true,
              autoplay: {
                delay: 3000,
              }
            });
          }
        },

        galleryTop: {
          selector: ".gallery-thumbs",
          init: function () {

           var galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
              640: {
                slidesPerView: 3,
                spaceBetween: 10
              }
            },
          });
           var galleryTop = new Swiper('.gallery-top', {
             spaceBetween: 10,
             loop: true,
             navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            thumbs: {
              swiper: galleryThumbs
            }
          });
           galleryTop.params.control = galleryThumbs;
           galleryThumbs.params.control = galleryTop;
         }
       },

        // Zoom on Images
        ZoomImg: {
          selector: ".wrapper-galerie .swiper-slide > a",
          init: function () {
            var base = this, container = $(base.selector);
            container.magnificPopup({
              type:'image',
              gallery: {
                enabled: true,
                preload: [0,2],
                navigateByImgClick: true,
                arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                tPrev: 'Previous (Left arrow key)',
                tNext: 'Next (Right arrow key)',
                tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
              }

            });
          }
        },

        // Close messages
        MessagesClose: {
          selector: ".messages",
          init: function () {
            var base = this, container = $(base.selector);
            container.prepend('<a href="#" class="close">×</a>');

            $(document).on("click", ".messages .close", function(e) {
              e.preventDefault();
              $(this).parents(".messages").slideUp(200);
            });
          }
        },
        // Lang menu
        LangMenu: {
          selector: "ul.language-switcher-locale-url",
          init: function () {
            var base = this, container = $(base.selector), activeLang = container.find('li.active a').text();

                // create span with active lang
                container.parent().prepend('<span class="label">'+activeLang+'<i class="fa fa-angle-down" aria-hidden="true"></i></span>');
                // hide  active lang in ul
                container.find('li.active').remove();

                //show ul
                container.parent().find('.label').on('click', function (e){
                  e.stopPropagation();
                  container.slideToggle(300);
                  $(this).find('.fa').toggleClass('fa-caret-down');
                  $(this).find('.fa').toggleClass('fa-caret-up');
                });

                //hide ul
                $(document).on('click', function(e) {
                  var $clicked = $(e.target);
                  if (!$clicked.parents().hasClass("lang")) {
                    container.slideUp(300);
                    container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
                    container.parent().find('.label').find('.fa').addClass('fa-caret-down');
                  }
                });

                $(document).scroll(function() {
                  if ($(document).scrollTop() >= 50) {
                    container.slideUp(300);
                    container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
                    container.parent().find('.label').find('.fa').addClass('fa-caret-down');
                  }
                });
              }
            }

        // Resize
      }, $win.resize(function() {

        // Scroll
      }), $win.scroll(function() {
        SITE.NavScrolled.init();

        // Ready
      }), $doc.ready(function() {
        SITE.init();
      });
    }(jQuery, this);
